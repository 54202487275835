<template>
	<div class="container formulario__ccntainer" id="App">
              <div class="formulario__header">
                <h2 class="section__title white">{{ lang.title }}</h2>
                <p class="white">{{ lang.description}}</p>
              </div>
		<form action="#" id="contact-form" @submit="sendForm" v-if="show">
			<div class="form-msg" :class="{ active: sent }">
				<img title="éxito" alt="éxito" class="form-msg__icon" src="assets/images/success-icon.svg">
				<h3 class="form-msg__title">{{ lang.successTitle }}</h3>
				<p class="form-msg__text"> {{ lang.successText }}</p>
			</div>
			<div class="form-msg" :class="{ active: sentError }">
				<img title="" alt="" class="form-msg__icon" src="assets/images/error-icon.svg">
				<h3 class="form-msg__title">{{ lang.errorTitle }}
				</h3>
				<p class="form-msg__text">{{ lang.errorText }}</p>
				<button class="form-msg__button" @click.prevent="sentError = false">{{ lang.errorButton }}</button>
			</div>
			<div class="formulario__datos">
				<div class="form__field">
					<input type="text" :placeholder="lang.companyName" :class="{ error: v$.form.companyName.$error }" name="company-name" id="company-name"
						v-model="form.companyName" @blur="v$.form.companyName.$touch()" maxlength="100">
					<p class="field-input__error-msg" :class="{ active: v$.form.companyName.$error }">
						{{ lang.errorCompanyName }}
					</p>
				</div>
				<div class="form__field">
					<input type="text" :placeholder="lang.cuit" :class="{ error: v$.form.cuit.$error }" name="cuit" id="cuit" v-model="form.cuit"
						@blur="v$.form.cuit.$touch()" v-maska="'##-########-#'">
					<p class="field-input__error-msg" :class="{ active: v$.form.cuit.$error }">
						{{ lang.errorCuit }}
					</p>
				</div>
				<div class="form__field">
					<input type="text" :placeholder="lang.contactName" :class="{ error: v$.form.contactName.$error }" name="contact-name" id="contact-name"
						v-model="form.contactName" @blur="v$.form.contactName.$touch()" maxlength="100">
					<p class="field-input__error-msg" :class="{ active: v$.form.contactName.$error }">
						{{ lang.contactName }}
					</p>
				</div>
				<div class="form__field">
					<input type="text" :placeholder="lang.contactRole" :class="{ error: v$.form.contactRole.$error }" name="contact-role" id="contact-role"
						v-model="form.contactRole" @blur="v$.form.contactRole.$touch()" maxlength="100">
					<p class="field-input__error-msg" :class="{ active: v$.form.contactRole.$error }">
						{{ lang.contactRole }}
					</p>
				</div>
				<div class="form__field">
					<input type="email" :placeholder="lang.contactEmail" :class="{ error: v$.form.contactEmail.$error }" name="contact-email" id="contact-email"
						v-model="form.contactEmail" @blur="v$.form.contactEmail.$touch()" maxlength="100">
					<p class="field-input__error-msg" :class="{ active: v$.form.contactEmail.$error }">
						{{ lang.contactEmail }}
					</p>
				</div>
				<div class="form__field">
					<input type="tel" :placeholder="lang.contactPhone" :class="{ error: v$.form.contactPhone.$error }" name="contact-phone" id="contact-phone"
						v-model="form.contactPhone" @blur="v$.form.contactPhone.$touch()" maxlength="22">
					<p class="field-input__error-msg" :class="{ active: v$.form.contactPhone.$error }">
						{{ lang.contactPhone }}
					</p>
				</div>
				<div class="custom-select">
					<div class="form__field" >
						<select class="formulario__select" name="motivo-consulta" id="motivo-consulta"
							v-model="form.motivoConsulta" @blur="v$.form.motivoConsulta.$touch()"
                     :class="{ error: v$.form.motivoConsulta.$error }"
                     >
							<option value="" disabled selected style="color: rgb(201, 119, 201); font-weight: bold;">
								{{ lang.motivoConsulta}}</option>
							<option :value="lang.motivoConsulta1" style="color: rgb(201, 119, 201); font-weight: bold;">
								{{ lang.motivoConsulta1 }}</option>
							<option :value="lang.motivoConsulta2" style="color: rgb(201, 119, 201); font-weight: bold;">
								{{ lang.motivoConsulta2 }}</option>
							<option :value="lang.motivoConsulta3" style="color: rgb(201, 119, 201); font-weight: bold;">
								{{ lang.motivoConsulta3 }}</option>
							<option :value="lang.motivoConsulta4" style="color: rgb(201, 119, 201); font-weight: bold;">
								{{ lang.motivoConsulta4 }}</option>
							<option :value="lang.motivoConsulta5" style="color: rgb(201, 119, 201); font-weight: bold;">
								{{ lang.motivoConsulta5 }}</option>
						</select>
						<span class="custom-arrow"><img src="assets/images/arrow.png" alt="flecha seleccionar opciones"></span>
					</div>
               <p class="field-input__error-msg" :class="{ active: v$.form.motivoConsulta.$error }">
                  {{ lang.errorMotivoConsulta}}
               </p>
				</div>
				<div class="form__field completo">
					<textarea class="formulario__comentarios" :class="{ error: v$.form.comments.$error }" cols="30" rows="10" :placeholder="lang.comments"
						name="comments" id="comments" v-model="form.comments" @blur="v$.form.comments.$touch()"
						maxlength="512"></textarea>
					<p v-if="!v$.form.comments.required.$response" class="field-input__error-msg" :class="{ active: v$.form.comments.$error }">
						{{ lang.errorComments }}
					</p>
               <p v-if="!v$.form.comments.isTextValid.$response" class="field-input__error-msg" :class="{ active: v$.form.comments.$error }">
						{{ lang.commentInvalid }}
					</p>
				</div>
			</div>
			<div class="formulario__botones">
				<div class="form__field">
					<vue-recaptcha v-show="showRecaptcha" :sitekey="siteKey" @verify="recaptchaVerified"
						@expire="recaptchaExpired" @fail="recaptchaFailed" ref="vueRecaptcha">
					</vue-recaptcha>
					<p class="field-input__error-msg" :class="{ active: v$.recaptcha.$error }">
						{{lang.errorRecaptcha}}
					</p>
				</div>
				<button class="button__spaced" type="submit" :disabled="loading">{{ lang.submitButton }}</button>
			</div>
		</form>
	</div>
</template>

<script>

import axios from 'axios';
import vueRecaptcha from 'vue3-recaptcha2';
import useVuelidate from '@vuelidate/core';
import {
	required,
	numeric,
	email,
} from '@vuelidate/validators';
import { maska } from 'maska';

const cuit = function (cuit) {
	if (cuit!="") {
		const cuitRegex = /[0-9]{2}-[0-9]{8}-[0-9]{1}/g;
		if (!cuitRegex.test(cuit)) return false;
		const [type, value, verificationDigit] = cuit.split('-');
		const numbers = (type + value).split('');
		const numericDigits = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
		const module =
			numbers.reduce((acum, num, index) => acum + num * numericDigits[index], 0) %
			11;
		const differenceValue = 11 - module;
		const validationNumber = differenceValue === 11 ? 0 : differenceValue;
		return validationNumber === 10 || validationNumber !== +verificationDigit
			? false
			: true;
	}
	else return true
};

//validacion para que acepte alfanumerico y espacios
const isTextValid = (value)=> /^[a-z0-9-_ \n]*$/i.test(value)

export default {
	directives: { maska },
	components: {
		vueRecaptcha,
	},
	name: 'SectionContact',
	data() {
		return {
			show: false,
			webApiURL: '',
			siteKey: '',
			showRecaptcha: false,
			btnMsg: 'Enviar',
			sent: false,
			sentError: false,
			loading: false,
			recaptcha: '',
			form: {
				companyName: '',
				cuit: '',
				contactName: '',
				contactRole: '',
				contactEmail: '',
				contactPhone: '',
				motivoConsulta: '',
				comments: '',
			},
			lang: {
				title: "",
				description: "",
				successTitle: "",
				successText: "",
				errorTitle: "",
				errorText: "",
				errorButton: "",
				companyName: "",
				errorCompanyName: "",
				cuit: "",
				errorCuit: "",
				contactName: "",
				errorContactName: "",
				contactRole: "",
				errorContactRole: "",
				contactEmail: "",
				errorContactEmail: "",
				contactPhone: "",
				errorContactPhone: "",
				motivoConsulta: "",
				motivoConsulta1: "",
				motivoConsulta2: "",
				motivoConsulta3: "",
				motivoConsulta4: "",
				motivoConsulta5: "",
				errorMotivoConsulta: "",
				comments: "",
				errorComments: "",
            commentInvalid: "",
				errorRecaptcha: "",
				submitButton: "",
			},
		};
	},
	validations() {
		return {
			recaptcha: {
				required,
			},
			form: {
				companyName: {
					required,
               isTextValid
				},
				cuit: {
					cuit,
				},
				contactName: {
					required,
				},
				contactRole: {
					required,
				},
				contactEmail: {
					required,
					email,
				},
				contactPhone: {
					required,
					numeric,
				},
				motivoConsulta: {
					required,
				},
				comments: {
					required,
					isTextValid
				},
			},
		};
	},
	setup() {
		return { v$: useVuelidate() };
	},
	async mounted() {
		const appsettings = await fetch('__yforms/appsettings.json').then(
			(response) => response.json()
		);
		let self = this;
		this.webApiURL = appsettings.webApiURL;
		this.siteKey = appsettings.siteKey;
		this.show = true;
		this.showRecaptcha = true;

		let docLang = document.documentElement.lang || "es";
		
		axios.get("./__yforms/" + docLang + ".txt").then(function (response) {
			let arr = response.data.split("\n");
			for (let i in arr) {
				if (arr[i].trim().length > 0) {
					let obj = arr[i].split("=>");
					if (typeof self.lang[obj[0]] != "undefined") {
						self.lang[obj[0]] = obj[1];
					}
				}
			}
			self.loaded = true;
		});
	},
	methods: {
		recaptchaVerified(response) {
			this.recaptcha = response;
		},
		recaptchaExpired() {
			this.recaptcha = '';
			this.$refs.vueRecaptcha.reset();
		},
		recaptchaFailed() {
			this.recaptcha = '';
		},
		sendForm(e) {
			e.preventDefault();
			let self = this;
			self.v$.$touch();
			if (!self.v$.$invalid) {
				self.loading = true;
				self.btnMsg = 'Enviando...';
				self.sentError = false;
				const webMethod = self.webApiURL + '/dataclient/' + 'submit';
            let docLang = document.documentElement.lang || "es";
				axios
					.post(
						webMethod,
						{
							formName: 'contactoquimica',
							captcha: self.recaptcha,
							captchaVersion: 'v2',
							form: {
								'companyName': self.form.companyName,
								cuit: self.form.cuit,
								'contactName': self.form.contactName,
								'contactRole': self.form.contactRole,
								'contactEmail': self.form.contactEmail,
								'contactPhone': self.form.contactPhone,
								'motivoConsulta': self.form.motivoConsulta,
								'comments': self.form.comments,
                        'language': docLang,
							},
						},
						{
							'Content-type': 'application/json; charset=utf-8',
							'Access-Control-Allow-Origin': '*',
						}
					)
					.then(function (response) {
						if (
							typeof response.data['status'] != 'undefined' &&
							response.data.status == 'OK'
						) {
							self.sent = true;
							self.form = {
								companyName: '',
								cuit: '',
								contactName: '',
								contactRole: '',
								contactEmail: '',
								contactPhone: '',
								motivoConsulta: '',
								comments: '',
							};
							!self.v$.$reset();
							self.scrolling('success-icon');
						} else {
							self.sentError = true;
							self.scrolling('error-icon');
						}
						self.loading = false;
						self.btnMsg = 'Enviar';
					})
					.catch(function () {
						self.sentError = true;
						self.loading = false;
						self.btnMsg = 'Enviar';
						self.scrolling('error-icon');
					});
			} else {
				for (let key in Object.keys(self.v$.form)) {
					const input = Object.keys(self.v$.form)[key];
					if (self.v$.form[input].$error) {
						self.scrolling(input);
						break;
					}
				}
			}
		},
		scrolling(name) {
			let elm = document.getElementById(name);
			if (elm != null && typeof elm.scrollIntoView != 'undefined') {
				elm.scrollIntoView();
			}
			window.scroll(0, window.scrollY - 150);
		},
		isJsonString(str) {
			try {
				JSON.parse(str);
			} catch (e) {
				return false;
			}
			return true;
		},
	},
}
</script>

<style>
</style>
